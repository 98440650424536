import React from 'react'
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Avika from './component/Avika';

const App = () => {
  return (
   <>
    <Avika />
   </>
  )
}

export default App;